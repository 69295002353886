html.ReactModal__Html--open {
  overflow: hidden;
}

body {
  margin: 0;
  margin-bottom: 12rem !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  font-size: 3rem;
  line-height: 1.125;
  font-weight: 600;
  word-break: break-word;
  color: #363636;
}

h3 {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  font-size: 2rem;
  line-height: 1.125;
  font-weight: 600;
  word-break: break-word;
  color: #363636;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("./fonts/Inter-Thin.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Thin.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: block;
  src: url("./fonts/Inter-ThinItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-ThinItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url("./fonts/Inter-ExtraLight.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-ExtraLight.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: block;
  src: url("./fonts/Inter-ExtraLightItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-ExtraLightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("./fonts/Inter-Light.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Light.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: block;
  src: url("./fonts/Inter-LightItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-LightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./fonts/Inter-Regular.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Regular.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("./fonts/Inter-Italic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url("./fonts/Inter-Medium.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Medium.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: url("./fonts/Inter-MediumItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-MediumItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("./fonts/Inter-SemiBold.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-SemiBold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: block;
  src: url("./fonts/Inter-SemiBoldItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-SemiBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("./fonts/Inter-Bold.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Bold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("./fonts/Inter-BoldItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-BoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: block;
  src: url("./fonts/Inter-ExtraBold.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-ExtraBold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: block;
  src: url("./fonts/Inter-ExtraBoldItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-ExtraBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("./fonts/Inter-Black.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-Black.woff?v=3.13") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: block;
  src: url("./fonts/Inter-BlackItalic.woff2?v=3.13") format("woff2"),
    url("./fonts/Inter-BlackItalic.woff?v=3.13") format("woff");
}
